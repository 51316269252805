'use client'

import { ChakraProvider, Flex, extendBaseTheme, extendTheme, createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"
import { menuAnatomy } from '@chakra-ui/anatomy';



import { RecoilRoot } from "recoil"
import CookieConsent from "react-cookie-consent";
import Link from "next/link";
const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(menuAnatomy.keys);


const variants = {
    menuBrasal: definePartsStyle({
        list: {
            py: '20',
            borderRadius: '0',
            border: 'none',
            bg: '#f1f1f1',
        },
        item: {
            bg: '#f1f1f1',
            borderRadius: '0',
        }
    }),
};

const menu = defineMultiStyleConfig({
    variants,
});

const breakpoints = {
    base: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    "2xl": "1313px",
};

const themeConfig = {
    fonts: {
        body: "'Plus Jakarta Sans', sans-serif"
    },
    zIndices: {
        modal: 30001
    },
    textStyles: {
        h4: {
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontWeight: '800',
            fontSize: '22px',
            letterSpacing: '-.88px',
            margin: '0 0 16px'
        }
    },
    components: {
        Menu: menu
    },
    breakpoints,
    sizes: {
        container: {
            breakpoints
        }
    }

}

const theme = extendTheme({ ...themeConfig })


const Providers = (props: any) => {
    return (
        <RecoilRoot>
            <ChakraProvider theme={theme}>
                {props.children}
            </ChakraProvider>
        </RecoilRoot>
    )
}
export default Providers
